import { internalAxios } from '../utils/axios/internal';

export const cartFromIdphysicsale = async ({ id }) => {
  const response = await internalAxios.get(`/api/payment/pay/${id}`);
  return response.data;
};

export const ok = async ({ idpayment }) => {
  const response = await internalAxios.get(`/api/payment/ok/${idpayment}`);
  return response;
};
