import { internalAxios } from '../../utils/axios/internal';

export const get = async ({ filter, image, promocode, quantity }) => {
  const response = await internalAxios.get('/api/wallet/physicproduct/list.json', { params: { filter, image, promocode, quantity } });
  return response.data;
};

export const getCart = async ({ type, image, promocode, quantity, idphysicproducts }) => {
  const response = await internalAxios.get('/api/wallet/physicproduct/cart.json', { params: { type, image, promocode, quantity, idphysicproducts } });
  return response.data;
};

export const productLegals = async ({ promocode, type, image, id, quantity }) => {
  const response = await internalAxios.get(`/api/wallet/physicproduct/cgv.json?promocode=${promocode}&quantity=${quantity}&image=${image}&type=${type}&id=${JSON.stringify(id)}`);
  return response.data;
};
